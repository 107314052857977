<template>
  <div class="d-flex flex-wrap mt-4">
    <div class="w-100 d-flex flex-wrap justify-content-center">
      <h1 class="w-100 text-center mb-4">Scan & Book</h1>
      <qrcode-vue class="d-block" :value="url" :size="300"></qrcode-vue>
    </div>
  </div>
</template>

<script>
import QrcodeVue from "qrcode.vue";
import LocationAPI from "/app/src/api/location.js";

export default {
  name: "CustomerBookingQR",
  components: { QrcodeVue },
  data: () => {
    return {
      locationAPI: new LocationAPI(),
      storageURL: process.env.VUE_APP_STORAGE_URL,
      location: {},
    };
  },
  created: function () {
    this.url = `${window.location.protocol}//${window.location.hostname}/customer-booking/${this.$route.params.id}`;
    this.getLocation(this.$route.params.id);
  },
  methods: {
    getLocation: function (id) {
      this.$store.dispatch("loading");
      let self = this;
      self.locationAPI
        .detail(id)
        .then((location) => {
          this.$store.dispatch("stopLoading");
          self.location = location;
        })
        .catch((error) => {
          this.$store.dispatch("stopLoading");
          console.log(error);
          this.$alert.show({ type: "danger", message: error });
        });
    },
  },
};
</script>

<style>
.location-logo {
  height: 150px;
}
</style>
